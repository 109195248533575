/* General page layout */
html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5; /* Light gray */
}

.team-member-page {
  padding: 50px 20px 80px; /* Adjusted padding for space below header and above footer */
  min-height: 100vh;
}

.main-header {
  text-align: center;
  margin-bottom: 40px;
}

.team-title-main {
  font-size: 2.5em;
  font-weight: bold;
  color: #73540f; /* Navy blue */
  margin-bottom: 10px;
}

.member-details {
  background-color: white;
  border: 2px solid #3f2e09; /* Purple border */
  border-radius: 12px;
  padding: 40px;
  margin: 0 auto;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.member-image {
  width: 100%;
  max-width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.member-name {
  font-size: 1.8em;
  font-weight: bold;
  color: #3f2e09; /* Navy blue */
  margin-bottom: 10px;
}

.member-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #815919; /* Muted purple */
  margin-bottom: 20px;
}

.member-description {
  font-size: 1em;
  color: #424242; /* Dark gray */
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 30px;
}

.back-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #283593; /* Navy blue */
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: #1a237e; /* Darker navy */
}
