html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.services-page {
  background-color: #EEEEEE;
  color: #333333;
  text-align: center;
  padding-top: 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Our Services Title Styling (unchanged from the previous code) */
.services-title {
  font-size: 2.5em;
  color: #73540f; /* Muted purple for the title accent */
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.title-underline {
  width: 120px;
  height: 3px;
  background-color: #73540f; /* Muted purple underline for consistency */
  margin: 0 auto 40px;
}

/* Bars Section Styling */
.services-bars-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding: 20px;
}

.service-bar {
  width: 70%;
  padding: 25px;
  background:  linear-gradient(90deg, #15031d, #5b2170) ;
  color: #ffffff;
  text-align: center;
  font-size: 1.3em;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  transition: transform 0.5s, background 0.3s;
  position: relative;
  overflow: hidden;
  margin: 0 auto; /* Centers the bars */
  
}

.service-bar:hover {
  background: linear-gradient(90deg, #edd6b1, #3c3325);
  transform: scale(1.1);
}

.service-bar.from-left {
  animation: slide-in-left 1s ease-in-out;
}

.service-bar.from-right {
  animation: slide-in-right 1s ease-in-out;
}

@keyframes slide-in-left {
  from {
      transform: translateX(-100%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes slide-in-right {
  from {
      transform: translateX(100%);
  }
  to {
      transform: translateX(0);
  }
}

/* Footer Section */
.services-footer {
  font-size: 1.1em;
  color: #424242;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #8e24aa;
}
