/* Ensure no margin or padding on body and html */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Contact Page Styling */
.contact-page {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  background-color: #EEEEEE; /* Soft grey background to match the overall theme */
  padding: 40px;
  color: #333333; /* Dark gray for text readability */
  font-family: 'Arial', sans-serif;
}

/* Styling for the contact form */
.contact-form {
  padding-bottom: 20px;
  width: 400px;
  background-color: #cdb793; /* Light lavender to complement the site's color scheme */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  height: 400px;
  margin-top: 130px;
}

.contact-form h2 {
  color: #73540f; /* Muted purple for form title */
  text-align: center;
  margin-bottom: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #445566; /* Dark gray for input border */
  border-radius: 4px;
  background-color: #f4e8d4; /* Light purple input background */
  color: #424242; /* Dark gray text inside inputs */
}

.contact-form button {
  padding: 10px;
  background-color: #624d2a; /* Muted purple for button background */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff; /* White text on button */
  font-weight: bold;
}

.contact-form button:hover {
  background-color: #302615; /* Slightly darker purple on hover */
}

/* Locations Section */
.locations {
  width: 60%;
}

.locations h2 {
  color: #73540f; /* Muted purple for section heading */
  text-align: center;
  margin-bottom: 20px;
}

.location-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.location-card {
  background-color: #cdb793; /* Light purple background */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.location-card h3 {
  color: #3f2b16; /* Navy blue for location card titles */
  margin-bottom: 10px;
}

.location-card p {
  margin: 5px 0;
  color: #512f2f; /* Dark gray for text inside location cards */
}

.location-card iframe {
  width: 100%;
  height: 200px;
  border: none;
  border-radius: 4px;
}

/* Popup container styling */
.popup {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #D4BEE4; /* Light lavender to match the site’s color palette */
  color: #424242; /* Dark gray text for readability */
  padding: 20px;
  border: 2px solid #8e24aa; /* Muted purple border for consistency */
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  opacity: 1;
  transition: opacity 1s ease-out;
  z-index: 1000;
}

/* Popup fade-out effect */
.popup.fade-out {
  opacity: 0;
}

/* Optional: add a little animation for the popup entry */
.popup-content {
  animation: popupEntry 0.5s ease-in-out;
}

/* Animation for popup entry */
@keyframes popupEntry {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
