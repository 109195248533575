/* General page layout */
html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5; /* Light gray for the background */
}

.team-page {
  padding: 30px 20px 80px; /* Adjusted padding for space below header and above footer */
}

.main-header {
  text-align: center;
  margin-bottom: 40px;
}

.team-title-main {
  font-size: 2.5em;
  font-weight: bold;
  color: #73540f; /* Navy blue */
  margin-bottom: 10px;
}

.team-subtitle {
  font-size: 1.2em;
  color: #455a64; /* Subtle contrast */
}

.outer-block {
  border: 2px solid #3f2e09; /* Purple border */
  border-radius: 10px;
  padding: 40px;
  margin: 20px auto;
  max-width: 1200px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.outer-block-title {
  text-align: center;
  font-size: 2em;
  color: #3f2e09; /* Muted purple */
  font-weight: bold;
  margin-bottom: 30px;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}

.team-card {
  background-color: #fbecd4; /* Light lavender */
  padding: 20px;
  border-radius: 12px;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
}

.team-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.team-name {
  font-size: 1.3em;
  font-weight: bold;
  color: #333333;
  margin: 0;
}
.team-title {
  font-size: 1.1em;
  font-weight: bold;
  color: #815919; /* Muted purple */
  margin:0;
}