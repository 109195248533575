/* Page background styling */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.page-background {
  background-color: #EEEEEE; /* Soft grey to align with the main site background */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px; /* Adds space above the About Us section to create gap from header */
  padding-bottom: 80px; /* Adds space below the About Us section to create gap from footer */
}

/* About Us container styling */
.about-us-container {
  background-color: #cdb793; /* Light lavender to complement the site’s color scheme */
  padding: 40px;
  border-radius: 12px;
  max-width: 1000px;
  color: #333333; /* Dark gray for readability */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.about-us-title {
  font-size: 36px;
  font-weight: bold;
  color: #73540f; /* Muted purple to match the main site's accent colors */
  text-align: center;
  margin-bottom: 25px;
}

/* Section styling */
.section {
  margin: 20px 0;
  padding: 30px;
  background-color: #fbecd4; /* Light purple for section backgrounds */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  text-align: center;
  color: #455a64; /* Dark grey text color for sections */
}

/* Section title styling */
.section-title {
  font-size: 20px;
  font-weight: bold;
  color: #283593; /* Navy blue, consistent with feature box headings */
  margin-bottom: 15px;
}

/* Section content styling */
.section-content {
  font-size: 16px;
  line-height: 1.6;
  color: #424242; /* Dark grey for body text consistency */
}

/* Divider styling */
.divider {
  height: 2px;
  background-color: #402f14; /* Purple divider for a subtle, clean break */
  margin: 30px auto;
  width: 80%;
}
