/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container for header (Non-sticky now) */
.header-container {
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
}

/* Top Black Header */
.top-header {
  background: #000000; /* Black strip */
  color: white;
  padding: 10px 20px;
  font-size: 14px;
}

.top-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}

/* Contact Info */
.contact-info {
  font-size: 14px;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons .icon {
  color: white;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons .icon:hover {
  color: #ffa500; /* Theme orange */
}

/* Navbar styling */
.navbar {
  background: white;
  border-bottom: 2px solid #f4ead7; /* Orange border */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.navbar.scrolled {
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Logo and Company Name */
.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 45px;
  margin-right: 10px;
}

.navbar-company-name {
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #ff8400; /* Orange color */
  text-decoration: none; /* Remove underline */
}

/* Navbar links */
.navbar-links ul {
  display: flex;
  list-style: none;
}

.navbar-links li {
  margin-left: 25px;
}

.navbar-links a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #ffa500; /* Matches theme */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-links ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background: white;
    padding: 10px;
    border: 1px solid #ffa500;
    display: none;
  }

  .navbar-links ul.open {
    display: flex;
  }
}
