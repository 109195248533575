/* General Styles */
.homepage {
  font-family: 'Poppins', sans-serif;
  background-color: #EEEEEE;
  color: #333333;
  text-align: center;
  padding: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hero Section */
.hero-section {
  padding: 50px 0 20px 0;
  background: linear-gradient(135deg, #fffefd 0%, #fffcf8 100%);/*  gradient with soft purple tones */
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  animation: slideIn 1s ease forwards;
}

/* Image Slider */
.image-slider {
  position: relative;
  width: 100%;
  max-width: 1250px;
  height: 500px;
  overflow: hidden;
  border-radius: 12px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  opacity: 0;
  transition: opacity 1s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-image.active {
  opacity: 1;
  z-index: 1;
}

.hero-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
}

/* Dot Navigation */
.dots {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #42a5f5;
}

/* Hero Section Text */
.hero-section h1 {
  color: #6a1b9a;
  font-size: 2.8em;
  margin-top: 20px;
  background: linear-gradient(135deg, #383f44, #302334);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 1s ease forwards;
}

.subtitle {
  color: #1d3e4d;
  font-size: 1.3em;
}

/* Intro Text */
.intro-text {
  background-color: #b4aeae;
  color: #362d2d;
  padding: 30px;
  margin: 30px 0;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  font-size: 1.1em;
  animation: fadeIn 1.2s ease forwards;
  opacity: 0;
}

/* Features Section */
.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  justify-content: center;
  margin-top: 50px;
}

.feature-box {
  background: #1f1725;  /* Gradient with soft lavender and purple */
  color: #5c6bc0;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.08), -8px -8px 16px rgba(255, 255, 255, 1);
  text-align: center;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  overflow: hidden;
  position: relative;
  animation: pulseAnimation 1.5s infinite;
}

.feature-box h2 {
  font-size: 1.6em;
  margin-bottom: 12px;
  color: whitesmoke;
}

.feature-box p {
  color: #899398;
  font-size: 1.1em;
}

.feature-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  transform: skewX(-25deg);
  transition: 0.5s;
}

.feature-box:hover::before {
  left: 100%;
}

.feature-box:hover {
  transform: translateY(-8px);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
}

/* Button Section */
.button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  gap: 25px;
}

.cta-button {
  background: linear-gradient(45deg, #9B5A2A, #8A4B2B);

  color: #ffffff;
  padding: 15px 45px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-6px);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
}

.cta-button svg {
  font-size: 1.6em;
  margin-right: 12px;
}

/* Keyframe Animations */
@keyframes slideIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulseAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .features {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
