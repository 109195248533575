.service-detail {
    background-color: #f4f4f4;
    color: #333;
    padding: 30px;
  }
  
  .service-title {
    font-size: 2.5em;
    color: #684b0d;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  
  .service-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2, h3 {
    color: #471002;
  }
  
  h3 {
    margin-top: 20px;
    font-size: 1.5em;
  }
  
  p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-top: 10px;
  }

  ul{
    margin-left: 50px;
  }
  
  