.App {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Makes sure that padding and border are included in element's total width and height */
}

h1, h2, h3, p {
  margin: 10px 0;
}

.main {
  padding: 2ch;
}


